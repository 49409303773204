import { template as template_199338335a8c4356a74be6ff9f92a539 } from "@ember/template-compiler";
const SidebarSectionMessage = template_199338335a8c4356a74be6ff9f92a539(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
