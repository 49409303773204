import { template as template_82c65078e0ab44c4ae9acef253576f78 } from "@ember/template-compiler";
const WelcomeHeader = template_82c65078e0ab44c4ae9acef253576f78(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
